import * as React from "react";
/** @jsx jsx */
import {jsx, css} from "@emotion/react";
import FormModal, {FormModalProps} from "../../../modals/formModal";
import {Button, Form, Input, InputNumber, Select, Space} from "antd";
import jsonRequest from "../../../../utils/request/jsonRequest";
import announceModal from "../../../modals/announceModal";
import {useEffect, useMemo, useState} from "react";
import styled from "@emotion/styled";
import SmsInput from "../../sign/SmsInput";
import {useRequest} from "ahooks";

const AmountIcon = styled.span`
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, serif;
  font-weight: 400;
  color: rgba(0,0,0,0.4);
  line-height: 12px;
`

const RateMessages = styled.div`
  margin: -9px 0 15px;
  padding-left: 76px;
  font-size: 14px;
  font-family: Inter-Regular, Inter,serif;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
`

export type WithdrawModalProps = {
  wallet: any;
  phone: string;
} & FormModalProps

export default (props: WithdrawModalProps) => {
  const {
    wallet,
    phone,
    open,
    ...modalProps
  } = props

  const [form] = Form.useForm();
  const [type, setType] = useState('bank')
  const [stage, setStage] = useState<'account' | 'sms'>('account')
  const [amount, setAmount] = useState(0)

  const {data: withdrawRate} = useRequest(() => {
    return jsonRequest(`/account/withdrawRate`).then(rsp => {
      return rsp.data.data
    })
  })

  const onFinish = (values) => {
    return jsonRequest(`/account/withdraw`, {
      method: 'POST',
      data: values,
    }).then(rsp => {
      console.log(rsp)
      if (rsp.success) {
        announceModal({
          type: 'success',
          title: '提现',
          message: '提现提交成功',
        });
      } else {
        announceModal({
          type: 'error',
          title: '提现失败',
          message: rsp.message,
        });
      }

      return rsp;
    })
  }

  const onValuesChange = (change) => {
    if (change.type) {
      setType(change.type)
    }
    if (change.amount) {
      setAmount(change.amount)
    }
  }

  const initialValues = useMemo(() => (
    {
      bank: wallet.withdrawAccount?.bank ?? '',
      bankAccount: wallet.withdrawAccount?.bankAccount ?? '',
      alipayAccount: wallet.withdrawAccount?.alipayAccount ?? '',
      type: 'bank',
      amount: 0,
    }
  ), [wallet])

  useEffect(() => {
    if (open) {
      setStage('account')
      setType('bank')
      Object.keys(initialValues).forEach(k => {
        form.setFieldValue(k, initialValues[k])
      })
    }
  }, [open, initialValues])

  return (<>
    <FormModal
      footer={stage === 'account' ? (
        <Button type="primary" onClick={() => setStage("sms")}>下一步</Button>
      ) : undefined}
      title='收益提现'
      width={438}
      {...{open}}
      {...modalProps}
      formProps={{
        labelCol: {
          span: 5,
        },
        wrapperCol: {
          span: 19,
        },
        form,
        // initialValues,
        onFinish,
        onValuesChange,
      }}
    >
      {
        type === 'bank' && (
          <>
            <Form.Item label='开户银行' name='bank' noStyle>
              <Input placeholder='请输入开户银行名称' readOnly hidden/>
            </Form.Item>
            <Form.Item label='银行卡号' name='bankAccount' noStyle>
              <Input placeholder='请输入银行卡号' readOnly hidden/>
            </Form.Item>
          </>
        )
      }
      {
        type === 'alipay' && (
          <>
            <Form.Item label='支付宝号' name='alipayAccount' noStyle>
              <Input placeholder='请输入支付宝号码' readOnly hidden/>
            </Form.Item>
          </>
        )
      }
      <div
        // @ts-ignore
        css={css`
            width: 100%;
            .ant-input-number-affix-wrapper-lg {
              padding: 0 0 0 11px;
            }
          `}
      >
        <Form.Item label='提现金额' name='amount' style={{width: '100%'}} hidden={stage !== 'account'}>
          <InputNumber
            placeholder='请输入提现金额'
            style={{width: '100%'}}
            min={0}
            prefix={<AmountIcon>¥</AmountIcon>}
          />
        </Form.Item>
      </div>
      <RateMessages>
        <div>提现费率: {((withdrawRate ?? 0) * 100).toFixed(2)}%</div>
        <div>预期到账: {(amount * (1 - withdrawRate)).toFixed(2)}</div>
      </RateMessages>
      <Form.Item label='收款账户' name='type' hidden={stage !== 'account'}>
        <Select>
          <Select.Option value='bank'>银行卡{initialValues.bankAccount}</Select.Option>
          <Select.Option value='alipay'>支付宝{initialValues.alipayAccount}</Select.Option>
        </Select>
      </Form.Item>
      {
        stage === 'sms' && (
          <>
            <Form.Item label='绑定手机'>{phone}</Form.Item>
            <Form.Item label='验证码' name='code'>
              <SmsInput
                phone='13420611739'
                url='/account/withdraw/sms'
              />
            </Form.Item>
          </>
        )
      }
    </FormModal>
  </>)
}
