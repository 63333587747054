import * as React from "react";
import {useEffect, useState} from "react";
import WithdrawAccountModal from "./WithdrawAccountModal";
import WithdrawModal from "./WithdrawModal";
import {useRequest} from "ahooks";
import jsonRequest from "../../../../utils/request/jsonRequest";
import {Button} from "antd";

const WithdrawButton = ({wallet, reload}) => {

  const {data} = useRequest(() => {
    return jsonRequest("/account/auth").then(rsp => rsp.data.data)
  })

  const [showAccountModal, setShowAccountModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)

  return (<>
    {!wallet?.withdrawAccount && (
      <span className="withdrawText">还没绑定收款账户?</span>
    ) || <span className="withdrawText clickable" onClick={() => setShowAccountModal(true)}>
      更新收款账户
    </span>}
    {
      !wallet.withdrawAccount && (
        <button
          className="withdrawButton"
          onClick={() => {
            setShowAccountModal(true)
          }}
        >
          提现
        </button>
      )
    }
    {
      wallet.withdrawAccount && (
        <button
          className="withdrawButton"
          onClick={() => {
            setShowWithdrawModal(true)
          }}
        >
          提现
        </button>
      )
    }
    <WithdrawAccountModal
      phone={data?.phone}
      onSuccess={() => {
        setShowAccountModal(false)
        reload()
      }}
      wallet={wallet}
      open={showAccountModal}
      onCancel={() => setShowAccountModal(false)}
    />
    <WithdrawModal
      phone={data?.phone}
      onSuccess={() => {
        setShowWithdrawModal(false)
        reload()
      }}
      {...{wallet}}
      open={showWithdrawModal}
      onCancel={() => setShowWithdrawModal(false)}
    />
  </>)
}

export default WithdrawButton
